// - VARIABLES
$sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$small: 18px;
$medium: 22px;
$large: 25px;
$grey: #333;

// - STYLES
body {
  font-family: $sans;
  color: #333;
  line-height: 25px;
}

p, li {
  line-height: 35px;
  font-size: $small;
}

.container {
  width: 90%;
  max-width: 600px;
  padding-top: 50px;
  margin: auto;
}

// IMAGES
.img-container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

//

i {
  padding-right: 10px;
}

a {
  text-decoration: none;
  color: orange;
}

h1:first-of-type {
  color: $grey;
}

footer {
  margin-bottom: 80px;
}

.linkBox {
  margin-bottom: 10px;
}

#btn {
  width: 80px;
  height: 40px;
  background-color: orange;
  background-image: url('images/play-pause.png');
  background-repeat: no-repeat;
  background-size: 25px auto !important;
  background-position: center center;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 3px;
  margin: auto;
  margin-top: 20px;
  opacity: 0.85;
}

#btn:hover {
  transform: scale(1.1);
  opacity: 1;
}

#waveform {
  width: 100%;
}

.waveform-container {
  background-color: rgb(242, 242, 242);
  padding: 20px;
  width: 120%;
  margin-left: -10%;
  border-radius: 5px;
  overflow: visible;
}

@media screen and (max-width: 900px) {
  .waveform-container {
    width: 90%;
    margin: auto;
  }
}
